<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-header">
      <el-select
        class="select-primary pagination-select"
        v-model="pagination.perPage"
        :placeholder="$t('COMMON.PER_PAGE')"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <reseller-selector
        v-if="
          !filterReseller &&
          !filterInvoice &&
          $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
        "
        @resellerChanged="(resellerId) => (selectedReseller = resellerId)"
      />

      <base-input
        v-model="query"
        type="search"
        prepend-icon="fas fa-search"
        :placeholder="$t('COMMON.SEARCH')"
        clearable
      />
    </div>
    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="resellerPayments"
          @sort-change="sortChange"
          row-class-name="cursor-pointer"
          @row-click="
            (row) => {
              viewResellerPayment(row);
            }
          "
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" />
          </div>
          <el-table-column label="Code" prop="code" min-width="220" />

          <el-table-column
            :label="$t('COMMON.DATE')"
            prop="date"
            sortable="date"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $timeZoneDateFormat(row.date, "LLLL") }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.STATUS')"
            prop="status"
            min-width="220"
          >
            <template v-slot="{ row }">
              <reseller-payment-status-badge :resellerPayment="row" />
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.AMOUNT')"
            prop="amount"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $formatCurrency(row.amount) }}
            </template>
          </el-table-column>

          <el-table-column
            v-if="
              !filterReseller &&
              !filterInvoice &&
              $currentUserCan($permissions.PERM_VIEW_ANY_RESELLERS)
            "
            :label="$t('COMMON.RESELLER')"
            prop="reseller"
            min-width="220"
          >
            <template v-slot="{ row }">
              <reseller :reseller="row.reseller" />
            </template>
          </el-table-column>

          <el-table-column
            v-if="!filterInvoice"
            :label="$t('COMMON.INVOICE')"
            prop="invoice.code"
            min-width="220"
          >
            <template v-slot="{ row }">
              <router-link :to="$objectViewRoute(row.invoice)">
                {{ row.invoice ? row.invoice.code : null }}
              </router-link>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.CREATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $timeZoneDateFormat(row.created_at, "LLLL") }}
            </template>
          </el-table-column>
          <el-table-column
            :fixed="$listActionsButtonsPosition()"
            min-width="120"
          >
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                :class="{
                  disabled: !$currentUserCan(
                    $permissions.PERM_VIEW_RESELLER_PAYMENTS
                  ),
                }"
              >
                <a
                  type="text"
                  @click="viewResellerPayment(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i
                    class="fa-light fa-arrow-up-right-and-arrow-down-left-from-center"
                  ></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div slot="footer" class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}
        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import { INVOICE_STATUS_SENT } from "@/constants/invoices";
import { BasePagination } from "@/components";
import ResellerSelector from "@/components/ResellerSelector.vue";
import ResellerPaymentStatusBadge from "./ResellerPaymentStatusBadge.vue";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  name: "reseller-payment-list-table",

  components: {
    BasePagination,
    ResellerSelector,
    ResellerPaymentStatusBadge,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    filterInvoice: {
      type: String,
      default: null,
      description: "Invoice id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-date",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      resellerPayments: [],
      INVOICE_STATUS_SENT: INVOICE_STATUS_SENT,
      loading: true,
      selectedReseller: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedReseller: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterInvoice: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { code: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "reseller,invoice",
        };

        if (this.filterReseller) {
          params = {
            ...params,
            filter: { ...params.filter, reseller: this.filterReseller },
          };
        }
        if (this.selectedReseller) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reseller: this.selectedReseller,
            },
          };
        }
        if (this.filterInvoice) {
          params = {
            ...params,
            filter: { ...params.filter, invoice: this.filterInvoice },
          };
        }

        await this.$store.dispatch("resellerPayments/list", params);
        this.resellerPayments = this.$store.getters["resellerPayments/list"];
        this.total = this.$store.getters["resellerPayments/listTotal"];
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    viewResellerPayment(resellerPayment) {
      this.$emit("onViewResellerPayment", resellerPayment);
      /* this.$router.push({
        name: "View ResellerPayment",
        params: { id: resellerPayment.id },
      }); */
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
